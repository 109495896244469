<div class="flex flex-col gap-6 pt-8">
    <div class="px-4">
        <span class="text-gray-900 text-lg font-semibold">Your documents</span>
    </div>
    <div class="flex flex-col">
        <div (click)="goToDocument(document)" *ngFor="let document of documents" class="px-6 py-4 flex items-center gap-4 border-b first:border-t cursor-pointer" [ngClass]="{'bg-gray-200': document.isCurrent === false || document.isArchived === true}">
            <div *ngIf="document.isCurrent && document.signatureRequired && !document.studentDocumentId && !document.isArchived">
                <exclamation-circle-outline-icon title="Document requires signature" svgClass="stroke-4 stroke-red-600" stroke="2" [size]="30"></exclamation-circle-outline-icon>
            </div>
            <div>
                <document-text-outline-icon [size]="40"></document-text-outline-icon>
            </div>
            <div>
                <div class="flex flex-col">
                    <span class="text-gray-900 text-sm font-medium">{{document.name}}</span>
                    <span class="text-gray-600 text-sm font-normal">Version {{document.version}} 
                        <span *ngIf="document.isCurrent === false || document.isArchived === true">(old version)</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>