<nav class="bg-white border-b">
    <div class="flex justify-between p-4 items-center">
        <div class="font-poppins font-medium flex flex-none justify-center items-center gap-2 text-2xl text-primary-700">
            <div class="flex items-center gap-2">
                <img class="w-10 h-10" src="/assets/images/logo.svg"/> FightCloud
            </div>
        </div>
        <div>
            <button (click)="logout()">Logout</button>
        </div>
    </div>
</nav>
