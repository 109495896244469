<div class="flex justify-center">
    <div class="max-w-screen-xl w-full sm:w-10/12 mt-4" *ngIf="signStudentDocument">
        <div class="px-4">
            <app-back-button></app-back-button>
        </div>
        <div class="p-4 ql-editor h-auto" [innerHTML]="signStudentDocument.documentContent"></div>
        <div class="mt-4 flex justify-center" *ngIf="signStudentDocument.signatureRequired">
            <h3 class="text-xl">Please sign below</h3>
        </div>
        <div class="my-4" *ngIf="signStudentDocument.signatureRequired">
            <app-signature-pad #signaturePad></app-signature-pad>
        </div>
        <div class="flex justify-end" *ngIf="signStudentDocument.signatureRequired && !signaturePadComponent?.signaturePad?.isEmpty()">
            <button (click)="clear()" class="focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-2 text-center text-gray-500 hover:text-white border border-gray-500 hover:bg-gray-600">Clear</button>
        </div>
        <div class="p-4" *ngIf="signStudentDocument.signatureRequired && !signaturePadComponent?.signaturePad?.isEmpty()">
            <button [disabled]="signaturePadComponent?.signaturePad?.isEmpty()" (click)="save()" class="h-[38px] w-full text-center select-none hover:cursor-pointer text-white border border-primary-600 bg-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0 font-medium rounded-lg text-sm px-4 py-2.5 flex justify-center items-center space-x-2">Submit</button>
        </div>
    </div>
</div>