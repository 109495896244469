import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignDocumentComponent } from './pages/authenticated/sign-document/sign-document.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule } from '@angular/forms';
import { DashboardComponent } from './pages/authenticated/dashboard/dashboard.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthenticatedComponent } from './pages/authenticated/authenticated.component';
import { DocumentListComponent } from './pages/authenticated/document-list/document-list.component';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { DocumentComponent } from './pages/authenticated/document/document.component';
import { BackButtonComponent } from './components/back-button/back-button.component';


@NgModule({
  declarations: [
    AppComponent,
    SignDocumentComponent,
    NavbarComponent,
    SignaturePadComponent,
    LoginComponent,
    DashboardComponent,
    AuthenticatedComponent,
    DocumentListComponent,
    DocumentComponent,
    BackButtonComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgHeroiconsModule
  ],
  providers: [
    importProvidersFrom(HttpClientModule),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
