import { Component, OnInit } from '@angular/core';
import { StudentDocument } from '../../../models/student-document.model';
import { DocumentService } from '../../../services/document.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  studentDocument: StudentDocument | undefined;

  constructor(private documentService: DocumentService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const studentDocumentId = Number(this.route.snapshot.paramMap.get('studentDocumentId'));
    if (studentDocumentId) {
      this.documentService.getStudentDocument(studentDocumentId).subscribe((studentDocument) => {
        this.studentDocument = studentDocument;
      });
    }
  }
}
