import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignDocumentComponent } from './pages/authenticated/sign-document/sign-document.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/authenticated/dashboard/dashboard.component';
import { authGuard } from './guards/auth.guard';
import { AuthenticatedComponent } from './pages/authenticated/authenticated.component';
import { DocumentListComponent } from './pages/authenticated/document-list/document-list.component';
import { DocumentComponent } from './pages/authenticated/document/document.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', canActivate: [authGuard], component: AuthenticatedComponent, children: [
    { path: '', component: DocumentListComponent },
    { path: 'document/:studentDocumentId', component: DocumentComponent },
    { path: 'document/sign/:documentVersionId', component: SignDocumentComponent }
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
