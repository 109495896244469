import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthToken, LoginStep } from '../../models/login-model';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { StudentService } from '../../services/student.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {
  loginSteps = LoginStep;
  currentStep: LoginStep = LoginStep.Login;
  email: string | undefined;
  errorMessage: string | undefined;
  loading: boolean = false;
  resent: boolean = false;

  @ViewChild('verifyForm') verifyForm: ElementRef<HTMLFormElement> | undefined;

  constructor(private authService: AuthService, private studentService: StudentService, private router: Router) {}

  login(resend: boolean) {
    this.resent = resend;

    if (resend && this.verifyForm) {
      const inputs = Array.from(this.verifyForm.nativeElement.querySelectorAll('input') as NodeList);

      for (const input of inputs) {
        const element = (input as HTMLInputElement);
        element.value = '';
        element.classList.remove("focus:ring-red-600", "border-red-600", "focus:border-red-600", "target:border-red-600");
      }
    }

    if (this.email) {
      if (!this.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        this.errorMessage = "Please enter a valid email.";
        this.loading = false;
        return;
      }

      this.loading = true;
      this.authService.passwordlessLogin(this.email).subscribe({ 
        next: () => {
          this.errorMessage = undefined;
          this.currentStep = LoginStep.Verify;
          this.loading = false;
        }, 
        error: () => { 
          this.currentStep = LoginStep.Verify;
          this.loading = false;
        }
      });
    }
  }

  verify() {
    this.loading = true;

    if (!this.verifyForm) {
      this.loading = false;
      return;
    }

    this.errorMessage = undefined;

    const inputs = Array.from(this.verifyForm.nativeElement.querySelectorAll('input') as NodeList);

    let errors = false;
    let value = '';

    for (const input of inputs) {
      const element = (input as HTMLInputElement);
      if (!element.value) {
        element.classList.add("focus:ring-red-600", "border-red-600", "focus:border-red-600", "target:border-red-600");
        errors = true;
      }

      value += element.value;
    }

    if (value.length !== 6) {
      this.errorMessage = 'That code doesn\'t match, please input again or resend another code.';
      this.loading = false;
      return;
    }

    if (this.email) {
      this.authService.passwordlessVerify(this.email, value).subscribe({ 
        next: (token: AuthToken) => { 
          this.authService.setAuth(token);
          this.studentService.getStudentDetail().subscribe({
            next: () => {
              this.router.navigateByUrl('/');
              this.loading = false;
            },
            error: (error) => {
              this.errorMessage = error.error;
            }
          })
        },
        error: () =>  {
          this.errorMessage = 'That code doesn\'t match, please input again or resend another code.';
          this.loading = false;
        }
      });
    }
  }

  onKey(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    target.classList.remove("focus:ring-red-600", "border-red-600", "focus:border-red-600", "target:border-red-600");

    switch (event.key) {
      case 'Backspace':
        this.#handleBackSpace(target);
        break;
      case 'ArrowLeft':
        this.#handleArrowLeft(target);
        break;
      case 'ArrowRight':
        this.#handleArrowRight(target);
        break;
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    if (!this.verifyForm) {
      return;
    }

    const inputs = Array.from(this.verifyForm.nativeElement.querySelectorAll<HTMLInputElement>('input'));
    const clipboardData = event.clipboardData;

    if (clipboardData) {
      const value = clipboardData.getData('text');

      if (inputs) for (const [i, input] of inputs.entries()) {
        input.value = value[i] || '';
      }
    }
  }

  onInput(event: Event) {
    const element = event.target as HTMLInputElement;

    if (element.classList.contains("")) {
      element.classList.remove("");
    }

    const nextSibling = element.nextElementSibling as HTMLInputElement;

    if (nextSibling && element.value) {
      nextSibling.focus();

      if (nextSibling.value) {
        nextSibling.select();
      }
    }
  }

  goBack() {
    this.errorMessage = undefined;
    this.currentStep = LoginStep.Login;
  }

  #handleBackSpace(element: HTMLInputElement) {
    if (element.value) {
      element.value = '';
      return;
    }

    if (element.previousElementSibling) {
      (element.previousElementSibling as HTMLInputElement).focus();
    }
  }

  #handleArrowLeft(element: HTMLInputElement) {
    const previousInput = element.previousElementSibling as HTMLInputElement;
    if (!previousInput) return;
    previousInput.focus();
  }

  #handleArrowRight(element: HTMLInputElement) {
    const nextInput = element.nextElementSibling as HTMLInputElement;
    if (!nextInput) return;
    nextInput.focus();
  }
}
