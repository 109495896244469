import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SignStudentDocument } from '../models/sign-student-document.model';
import { StudentDocument } from '../models/student-document.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  getDocuments() {
    return this.http.get<StudentDocument[]>(`${environment.baseUrl}/studentdocument`);
  }

  getStudentDocument(studentDocumentId: number) {
    return this.http.get<StudentDocument>(`${environment.baseUrl}/studentdocument/${studentDocumentId}`);
  }


  getDocument(documentVersionId: number) {
    return this.http.get<SignStudentDocument>(`${environment.baseUrl}/studentdocument/sign-document/${documentVersionId}`);
  }

  saveDocument(documentVersionId: number, signatureUrl: string) {
    return this.http.post(`${environment.baseUrl}/studentdocument/sign-document/${documentVersionId}`, { signatureUrl: signatureUrl });
  }
}
