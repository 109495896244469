import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentService } from '../../../services/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SignStudentDocument } from '../../../models/sign-student-document.model';
import { SignaturePadComponent } from '../../../components/signature-pad/signature-pad.component';

@Component({
  selector: 'app-sign-document',
  templateUrl: './sign-document.component.html',
  styleUrls: ['./sign-document.component.scss']
})
export class SignDocumentComponent implements OnInit {
  #documentVersionId: number | null = null;
  signStudentDocument: SignStudentDocument | undefined;
  @ViewChild("signaturePad") signaturePadComponent: SignaturePadComponent | undefined;

  constructor(private documentService: DocumentService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.#documentVersionId = Number(this.route.snapshot.paramMap.get('documentVersionId'));
    if (this.#documentVersionId) {
      this.documentService.getDocument(this.#documentVersionId).subscribe((signStudentDocument) => {
        this.signStudentDocument = signStudentDocument;
      });
    }
  }

  save() {
    this.documentService.saveDocument(this.#documentVersionId!, this.signaturePadComponent?.signaturePad.toDataURL()!).subscribe(() => this.router.navigateByUrl('/'));
  }

  clear() {
    this.signaturePadComponent?.signaturePad.clear();
  }
}
