export enum LoginStep {
    Login = 1,
    Verify = 2
}

export interface AuthToken {
    tokenType: string;
    accessToken: string;
    expiresIn: number;
    expiresAt?: Date;
    refreshToken: string;
}